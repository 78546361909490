import React from 'react'
import { Link, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Logo from './assets/logo.png'

const Footer = () => {
  return (
    <Grid container direction="row" justifyContent='center' style={{marginTop:'80px', backgroundColor:'#ffffff'}}>
        <Grid item sm={5} >
          <img src={Logo} width='150px' style={{marginLeft:'20px', marginTop:'20px', marginBottom:'20px', textAlign:'left'}} alt='Logo'></img>
        </Grid>
        <Grid item sm={3} >
          <Typography paragraph color='textSecondary'style={{marginTop:'20px'}} >
          LeapFrog Executive Services <br/>
          Elsterweg 8<br/>
          D-65812 Bad Soden<br/>
          info@leapfrog-services.de
        </Typography>
        </Grid>
        <Grid item sm={4} >
          <Typography paragraph color='textPrimary' style={{textAlign:'right', paddingRight:'50px', marginTop:'20px'}}>
          <Link target={'_blank'} rel="noreferrer" href="https://www.leapfrog.de/impressum/" color='textSecondary'>Impressum</Link>
        </Typography>
        </Grid>
      </Grid>
  )
}

export default Footer