import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Hero from './assets/hero.jpg'
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    heroImage: {
      backgroundImage: `url(${Hero})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: '50% 50%',
      height: '85vh',
      width:'100wh',
      position: 'relative',
      marginBottom: '20px'
    },
    heroTitle:{
      position: 'absolute',
      width: '90%',
      bottom: 50,
      left: 50,
      background: `rgba(0, 0, 0, 0)`,
      color: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    }
  }))
const HeroHeader = () => {
    const classes = useStyles();
    return (
        <div className={classes.heroImage}>     
            <Typography variant={'h1'} className={classes.heroTitle}>
                LeapFrog Executive Services
            </Typography>
        </div>
    )
}

export default HeroHeader