import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';

let theme = createTheme({
    palette: {
        background:{
            paper:'#85A9C3',
            default:'#85A9C3'
        },
        primary: {
            main:'#85A9C3'
        },
        text:{
            primary:'#ffffff',
            secondary: '#203864'
        }
    },
    typography:{
       fontSize: 20,
       h1:{
           fontFamily:"Merriweather",
           fontSize: 90,
           fontWeight: 500
       },
       h4:{
        fontSize: 40,
        fontWeight: 500
    }
    }
});

theme = responsiveFontSizes(theme)

export default theme