import React from "react";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  videoResponsive : {
    overflow: 'hidden',
    paddingBottom: '56.25%',
    position: 'relative',
    height: 0,
  },
  iframe :{
    left: 0,
    top: 0,
    height: '100%',
    width: '100%',
    position: 'absolute',
  },
  }))
const YoutubeEmbed = ({ embedId }) => {
    const classes = useStyles()
    return (
        <div className={classes.videoResponsive}>
            <iframe 
                className={classes.iframe}
                width="560" 
                height="315" 
                src={embedId} 
                title="LeapFrog Executive Service" 
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen
            />
      </div>
    )
}

export default YoutubeEmbed