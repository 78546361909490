import React from 'react';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
//import YoutubeEmbed from './YoutubeEmbed'


function AdvisoryText() {
  return (
    <Grid container  direction="row" justifyContent='center' >
    <Grid item xs={11} >
      <Typography variant={"h4"} color='textPrimary' style={{marginBottom:'20px', marginTop:'20px'}}>
        Für Aufsichtsratsmitglieder
      </Typography>
      <Typography paragraph color='textPrimary'>
      Vom Management in den Aufsichtsrat: der Sprung als Geschäftsführer, Board Member oder Executive 
      in einen oder mehrere Aufsichtsräte ist für viele ein logischer Schritt: Als Wissensträger 
      und Netzwerker sind Sie aufgrund ihrer jahrzehntelangen Berufserfahrung prädestiniert für 
      einen Wechsel in die Kontrollgremien großer Unternehmen... und erleben viel mehr Freiheiten 
      und Gestaltungsspielräume als früher in ihrer Vorstandsrolle. Sie können sich 
      Aufsichtsratsmandate und deren Ausschüsse nach den eigenen Präferenzen aussuchen und 
      sind frei vom üblichen Business-Alltag bzw. operativen Aufgaben.
      </Typography>
      <Typography paragraph color='textPrimary'>
      Wenn da nur nicht die erforderlichen (aber leider sehr eintönigen) Arbeitsaufwände für die 
      Erarbeitung notwendiger Entscheidungspapiere und -präsentationen wären: Leitlinien, 
      Bewertungen und Strategien analog zur Geschäftsordnung bzw. Satzung, die Unmengen an 
      vorbereitenden Dokumenten mit sich bringen. Die in den Gremien getroffenen Entscheidungen 
      und Strategien brauchen fundierte Analysen und Herleitungen und müssen regulatorischen 
      Standards genügen. Früher hatte man als Manager für solche Aufgaben eigene Mitarbeiter… 
      einen Stab, Assistenten, eine Strategieabteilung… aber als Aufsichtsratsmitglied ist das 
      nicht immer so.
      </Typography>
    </Grid>
    <Grid item xs={11}>
      <Typography variant={"h4"} color='textPrimary' style={{marginBottom:'20px', marginTop:'50px'}}>
        Die Lösung
      </Typography>
      <Typography paragraph color='textPrimary'>
      LeapFrog Executive Services bietet Ihnen ein Expertenteam, quasi ein Executive-Office-as-a-Service, 
      das jahrzehntelange Erfahrung in der Erstellung und Aufbereitung von board-fähigen Unterlagen hat. 
      In drei Schritten entwickeln wir Business Stories und visuell hochwertige Unterlagen 
      (z. B. in Powerpoint) für Berater, Geschäftsführer und Aufsichtsratsmitglieder:
      </Typography>
      <ul>
        <li>
          <Typography paragraph color='textPrimary'>
            Schritt 1: Auf Basis der jeweiligen Mandatsaufgaben und -termine entwickeln wir mit Ihnen einen 
            Zeitplan für all Ihre Anforderungen im Rahmen von Terminvorbereitungen, Erarbeitungen von 
            Gremiumsempfehlungen und Leitlinien, Strategie- und Positionspapieren u. ä.          
          </Typography>
        </li>
        <li>
          <Typography paragraph color='textPrimary'>
            Schritt 2: Basierend auf diesem Plan strukturieren und extrahieren wir alle relevanten Informationen 
            aus den bereitgestellten Unterlagen - bei Bedarf angereichert durch unsere Datenquellen 
            sowie strategischen Methoden und Tools          
          </Typography>
        </li>
        <li>
          <Typography paragraph color='textPrimary'>
            Schritt 3: Das finale Ergebnis wird termingerecht in mehreren Iterationen (Anzahl je nach Aufwand) 
            professionell und im Rahmen der Vorgaben aufbereitet
          </Typography>
        </li>
      </ul>
      <Typography paragraph color='textPrimary'>
        Während dieses Prozesses kann der Stand der Unterlagen 24/7 eingesehen und bei Bedarf mit weiterem Input angereichert werden. 
        Optional bieten wir Ihnen die Möglichkeit, alle Dokumente, die Sie im Rahmen Ihrer Tätigkeit erhalten bzw. generieren, nach den 
        gesetzlichen Regelungen für Aufsichtsratsmitglieder strukturiert zu archivieren und deren Nutzung zu protokollieren.     
      </Typography>
      <Typography variant={"h4"} color='textPrimary' style={{marginBottom:'20px', marginTop:'50px'}}>
        Ihr Vorteil
      </Typography>
      <Typography paragraph color='textPrimary'>
      Bei einem Aufsichtsratsmandat mit jährlich ca. 50 - 80 TEUR sparen wir Ihnen pro Mandat bis zu 40% Ihrer Zeit... und verrechnen 
       zu einem günstigeren durchschnittlichen Stunden- bzw. Tagessatz! In Summe bleibt somit mehr Umsatz bei unseren Kunden.
      </Typography>
    </Grid>
    <Grid item xs={11} style={{paddingTop:'50px'}}>
      {/* space for youtube embed */}
    </Grid>
  </Grid>
          
  );
}

export default AdvisoryText;
