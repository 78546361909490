import React from 'react';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import YoutubeEmbed from './YoutubeEmbed'


function ConsultantText() {
  return (
    <Grid container  direction="row" justifyContent='center' >
    <Grid item xs={11} >
      <Typography variant={"h4"} color='textPrimary' style={{marginBottom:'20px', marginTop:'20px'}}>
        Für Unternehmensberater
      </Typography>
      <Typography paragraph color='textPrimary'>
        Vom Konzern in die Selbstständigkeit: der Sprung als Geschäftsführer, Board Member oder Executive in die 
        eigenständige Unternehmensberatung ist für viele ein langgehegter Traum. Als Wissensträger und Netzwerker 
        sind Sie nun aufgrund ihrer jahrzehntelangen Berufserfahrung gefragter denn je... 
        und erleben viel mehr Freiheiten und Gestaltungsspielräume als früher in ihrer Vorstandsrolle. 
        Sie können sich Projekte nach ihren Vorlieben aussuchen und sind frei von zeitaufwendigen Unternehmensvorgaben 
        und -prozessen.
      </Typography>
      <Typography paragraph color='textPrimary'>
        Wenn da nur nicht die erforderlichen (aber leider sehr eintönigen) Arbeitsaufwände für gute Entscheidungspapiere 
        und -präsentationen wären. Die eigenen Projektentscheidungen und -strategien brauchen fundierte Analysen und 
        Herleitungen, müssen die Auftraggeber und/oder deren Kunden überzeugen und sollten die beste Referenz für 
        Folge- und Neuaufträge sein. Früher hatte man als Manager für solche Aufgaben eigene Mitarbeiter… einen Stab, 
        Assistenten, eine Strategieabteilung… aber als selbstständiger Berater ist dem nun nicht mehr so.
      </Typography>
    </Grid>
    <Grid item xs={11}>
      <Typography variant={"h4"} color='textPrimary' style={{marginBottom:'20px', marginTop:'50px'}}>
        Die Lösung
      </Typography>
      <Typography paragraph color='textPrimary'>
        LeapFrog Executive Services bietet Ihnen ein Expertenteam, quasi ein Executive-Office-as-a-Service, 
        das jahrzehntelange Erfahrung in der Erstellung und Aufbereitung von board-fähigen Unterlagen hat. 
        In drei Schritten entwickeln wir Business Stories und visuell hochwertige Unterlagen (z. B. in Powerpoint) 
        für Berater, Geschäftsführer und Aufsichtsratsmitglieder:
      </Typography>
      <ul>
        <li>
          <Typography paragraph color='textPrimary'>
            Schritt 1: Wir gehen die Problemstellung sowie ein erstes Lösungskonzept gemeinsam durch und entwickeln direkt einen ersten Entwurf der Story
          </Typography>
        </li>
        <li>
          <Typography paragraph color='textPrimary'>
            Schritt 2: Basierend auf diesen Informationen bereiten wir - bei Bedarf angereichert durch unsere Datenquellen sowie strategischen Methoden und Tools - die Story visuell auf
          </Typography>
        </li>
        <li>
          <Typography paragraph color='textPrimary'>
            Schritt 3: Nach 1 - 3 Iteration (je nach Aufwand) bereiten wir das finale Ergebnis auf
          </Typography>
        </li>
      </ul>
      <Typography paragraph color='textPrimary'>
        Während dieses Prozesses kann der Stand der Unterlagen 24/7 eingesehen und bei Bedarf mit weiterem Input angereichert werden.
      </Typography>
      <Typography variant={"h4"} color='textPrimary' style={{marginBottom:'20px', marginTop:'50px'}}>
        Ihr Vorteil
      </Typography>
      <Typography paragraph color='textPrimary'>
        Bei einem klassischen Beraterprojekt von 20 Personentagen 
        sparen wir Ihnen pro Projekt oder Mandat bis zu 40% Ihrer Zeit... und verrechnen Ihnen einen günstigeren 
        durchschnittlichen Stunden- bzw. Tagessatz, als Sie Ihrem Kunden! In Summe bleibt somit mehr Projektumsatz bei Ihnen!
      </Typography>
    </Grid>
    <Grid item xs={11} style={{paddingTop:'50px'}}>
      <YoutubeEmbed embedId='https://www.youtube.com/embed/gH5JPuEU77M?controls=0&rel=0&modestbranding=1'/>
    </Grid>
  </Grid>
          
  );
}

export default ConsultantText;
