import React from 'react'
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

const Navigation = ({content, setContent}) => {
    return (
      <Grid container direction="row" justifyContent='center' style={{marginTop:'40px', marginBottom:'40px'}}>
      {content === 'consultantText' ? 
        <Grid item sm={11} onClick = {()=> setContent('advisoryText')} style={{textAlign: "center", textDecoration: 'underline'}}>
          <Typography variant={'h6'} >
            Aufsichtsratsmitglied? Bitte hier entlang...
          </Typography>
        </Grid>
        :
        <Grid item sm={11} onClick = {() => setContent('consultantText')} style={{textAlign: "center", textDecoration: 'underline'}}>
          <Typography variant={'h6'} >
            Unternehmensberater bitte hier klicken
          </Typography>
      </Grid>
      }  
    </Grid>
    )
}

export default Navigation