import React from 'react'
import Container from '@material-ui/core/Container'
import { CssBaseline } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/core/styles'
import theme from './material_theme'
import ConsultantText from './consultantText'
import AdvisoryText from './advisoryText'
import HeroHeader from './heroHeader'
import Navigation from './navigation'
import Footer from './footer'

function App() {
  const [content, setContent] = React.useState('consultantText')
  return (
    <ThemeProvider theme={theme} >
      <CssBaseline/>
      <Container maxWidth={false} disableGutters >
        <HeroHeader/>
        <Navigation content={content} setContent={setContent}/>
        {content === 'consultantText' ?  <ConsultantText/> : <AdvisoryText/>}
        <Footer/>
      </Container>
    </ThemeProvider>
  )
}

export default App
